
import { Component, Vue } from 'vue-property-decorator';
import { AnalyticType } from '@/interfaces/analytic-type';
import { Model, ModelStatusType, TrainingStatusType } from '@/interfaces/model';
import { EnvType } from '@/interfaces/env';
import EditableCell from '@/components/EditableCell.vue';
import ErrorLogBody from '@/components/ErrorLogBody.vue';
import { FormModel } from 'ant-design-vue';
import API from '@/services/rest-api';
import { Repository } from '@/enums/repos';
import Constants from '@/services/constants';

export interface MenuEvent extends Event {
  key: string;
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    width: 100
  },
  {
    title: 'Name',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'name' },
    width: 200
  },
  {
    title: 'Model version',
    dataIndex: 'version',
    key: 'version',
    scopedSlots: { customRender: 'version' }
  },
  {
    title: 'Image version',
    dataIndex: 'imageName',
    key: 'imageName',
    width: 100
  },
  {
    title: 'Analytic',
    dataIndex: 'analyticType',
    key: 'analyticType',
    filters: [
      { text: AnalyticType.SOWING, value: AnalyticType.SOWING },
      { text: AnalyticType.WEEDS, value: AnalyticType.WEEDS },
      { text: AnalyticType.SAT_WEEDS, value: AnalyticType.SAT_WEEDS },
      { text: AnalyticType.SAT_NEMATODES, value: AnalyticType.SAT_NEMATODES },
      { text: AnalyticType.SAT_ANOMALY, value: AnalyticType.SAT_ANOMALY },
      { text: AnalyticType.SAT_HARVEST, value: AnalyticType.SAT_HARVEST },
      { text: AnalyticType.SAT_SUGAR, value: AnalyticType.SAT_SUGAR },
      { text: AnalyticType.FLOWERING, value: AnalyticType.FLOWERING },
      { text: AnalyticType.LAND_USE, value: AnalyticType.LAND_USE },
      { text: AnalyticType.DEFORESTATION, value: AnalyticType.DEFORESTATION }
    ],
    onFilter: (value, record) => record.analyticType === value
  },
  {
    title: 'Traininig',
    key: 'training',
    scopedSlots: { customRender: 'training' }
  },
  {
    title: 'Build status',
    dataIndex: 'buildStatus',
    key: 'buildStatus',
    scopedSlots: { customRender: 'buildStatus' }
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    scopedSlots: { customRender: 'notes' }
  },
  {
    title: 'Envs',
    dataIndex: 'envs',
    key: 'envs',
    scopedSlots: { customRender: 'envs' },
    width: 350
  },
  {
    title: 'Actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' }
  }
];
@Component({
  components: {
    EditableCell,
    ErrorLogBody
  }
})
export default class ModelBuild extends Vue {
  modelStatusType = ModelStatusType;
  trainingStatusType = TrainingStatusType;

  private columns = columns;
  private isModaVisible = false;
  isAnalyticSelectionVisisble = false;
  private analyticType = AnalyticType;
  private form = {
    analyticType: AnalyticType.SOWING,
    isExisting: false,
    version: undefined,
    trainingSetId: undefined
  };
  private buildForm = {
    analyticType: undefined
  };

  isSubmitBtnEnabled = true;
  isBuildBtnEnabled = true;
  trainingSets = [];
  isTrainingSetsLoading = false;

  private isErrorLogModaVisible = false;
  private errorLog = '';
  private envDEV: string = null;
  private envPREPROD: string = null;
  private envPROD: string = null;
  analyticTypesToBuild = [];
  analyticsToBuild = ['sat-sugar', 'sat-harvest', 'deforestation', 'land-use'];
  public reposDS = Constants.DS_REPOS;
  runRegressionTests = true;
  rebuildAnalytics = true;
  clearSurveys = true;
  defaultDaysToClear = 5000;

  rules = {
    version: [
      {
        message: 'Length should be at least 6 chars',
        min: 6,
        trigger: ['change', 'blur']
      },
      {
        validator: (rule: { required?: boolean }, value: string, callback: (error?: Error) => void): void => {
          // "" or undefined
          if (!value && this.form.isExisting) {
            callback(new Error('version is required'));
          } else {
            callback();
          }
        },
        trigger: ['change', 'blur']
      }
    ],
    analyticType: [{ required: true, message: 'analyticType is required', trigger: 'change' }],
    trainingSetId: [
      {
        validator: (rule: { required?: boolean }, value: string, callback: (error?: Error) => void): void => {
          if (!value && !this.form.isExisting) {
            callback(new Error('trainingSetId is required'));
          } else {
            callback();
          }
        },
        trigger: ['change', 'blur']
      }
    ]
  };
  toggleSelection(selected: boolean): void {
    this.analyticTypesToBuild.forEach((x) => (x.selected = selected));
  }
  get isBuildInProgress(): boolean {
    return this.analyticTypesToBuild.some((x) => x.buildStatus === ModelStatusType.InProgress);
  }
  get dataSource(): Model[] {
    return this.$store.state.model.filteredData;
  }

  get modelAnalytics(): AnalyticType[] {
    return Object.keys(AnalyticType).map((key) => {
      return AnalyticType[key];
    });
  }

  get versionPlaceholder(): string {
    const placeholderByAnalyticType = {
      [AnalyticType.SOWING]: 'v0.0.0',
      [AnalyticType.WEEDS]: 'v0.0.0',
      [AnalyticType.SAT_SUGAR]: 'v0.0.0',
      [AnalyticType.FLOWERING]: 'v0.0.0',
      [AnalyticType.LAND_USE]: 'v0.0.0',
      [AnalyticType.DEFORESTATION]: 'v0.0.0',
      default: 'Input the docker version like v0.0.0 or AnalyticsNameV0.0.0'
    };
    const placeholder = placeholderByAnalyticType[this.form.analyticType] || placeholderByAnalyticType['default'];
    return placeholder;
  }

  async mounted(): Promise<void> {
    this.$store.dispatch('repo/loadRepos');

    const [dev, preprod, prod] = await Promise.all([
      API.getEnv(Repository.ANALYTIC_LAUNCHER, EnvType.DEV),
      API.getEnv(Repository.ANALYTIC_LAUNCHER, EnvType.PREPROD),
      API.getEnv(Repository.ANALYTIC_LAUNCHER, EnvType.PROD)
    ]);

    this.envDEV = dev;
    this.envPREPROD = preprod;
    this.envPROD = prod;

    this.analyticTypesToBuild = [];
    this.$store.dispatch('model/loadData');
  }

  public getLatestReleaseTag(dsRepo: string): string {
    if (Constants.DS_REPO_MULTIDOCKER.includes(dsRepo as Repository)) {
      const releases = this.$store.state.repo.filteredRepos.find((repo: any) => repo.name === dsRepo)?.prod?.releases;

      const analyticRelease = releases?.find((release: any) =>
        release.name.includes(Constants.DS_REPO_ANALYTIC_MAP[dsRepo])
      );

      return analyticRelease?.name;
    }

    return this.$store.state.repo.filteredRepos.find((repo: any) => repo.name === dsRepo)?.prod?.tag_name;
  }

  public getRepoLabel(deRepo: string): string {
    return Constants.DS_REPO_LABELS[deRepo];
  }

  public getCurrentModel(model: Model, env: EnvType): string {
    const file = env === EnvType.PROD ? this.envPROD : env === EnvType.PREPROD ? this.envPREPROD : this.envDEV;

    const getVersion = (str, indexStart, indexEnd) => {
      const model = str?.substring(str.indexOf(indexStart), str.indexOf(indexEnd));
      return model?.slice(model.indexOf('"')).replace(/"/g, '');
    };

    if (model.analyticType === AnalyticType.SAT_HARVEST) {
      return getVersion(file, 'SAT_HARVEST_IMAGE', 'SAT_HARVEST_RESULTS_BUCKET');
    }

    if (model.analyticType === AnalyticType.SAT_SUGAR) {
      return getVersion(file, 'SAT_SUGAR_IMAGE', 'SAT_SUGAR_RESULTS_BUCKET');
    }
    if (model.analyticType === AnalyticType.LAND_USE) {
      return getVersion(file, 'LAND_USE_IMAGE', 'LAND_USE_RESULTS_BUCKET');
    }
    if (model.analyticType === AnalyticType.DEFORESTATION) {
      return getVersion(file, 'DEFORESTATION_IMAGE', 'DEFORESTATION_RESULTS_BUCKET');
    }
    if (model.analyticType === AnalyticType.SAT_NEMATODES) {
      return getVersion(file, 'SAT_NEMATODES_IMAGE', 'SAT_NEMATODES_RESULTS_BUCKET');
    }

    if (model.analyticType === AnalyticType.SOWING) {
      return getVersion(file, 'DRONE_SOWING_IMAGE', 'DRONE_WEEDS_IMAGE');
    }

    if (model.analyticType === AnalyticType.WEEDS) {
      return getVersion(file, 'DRONE_WEEDS_IMAGE', 'DRONE_WEEDS_MACHINE_TYPE');
    }

    if (model.analyticType === AnalyticType.FLOWERING) {
      return getVersion(file, 'FLOWERING_IMAGE', 'FLOWERING_VM_TYPE');
    }

    return '';
  }

  onSearch(value: string): void {
    this.$store.dispatch('model/filterData', { search: value });
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    return { y: window.innerHeight - headerHeight };
  }

  resetForm(): void {
    this.form = {
      analyticType: AnalyticType.SOWING,
      isExisting: false,
      version: undefined,
      trainingSetId: undefined
    };
  }
  async handleBuild(): Promise<void> {
    this.isBuildBtnEnabled = false;
    const daysToClear = this.clearSurveys ? this.defaultDaysToClear : 0;

    const selectedModels = this.rebuildAnalytics
      ? this.analyticTypesToBuild
          .filter((x) => ['sat-sugar', 'sat-harvest', 'deforestation', 'land-use'].includes(x.analytic))
          .map((x) => x.selectedVersion)
      : [];
    let analyticsToTest = [];
    if (this.runRegressionTests) {
      analyticsToTest = this.analyticTypesToBuild.filter((x) => x.selected).map((x) => x.analytic);
    }
    API.BuildModelsAndRunRegressionTests(selectedModels, analyticsToTest, daysToClear);
    for (const id of selectedModels) {
      await this.waitUntilBuildFinished(id);
    }
    this.isBuildBtnEnabled = true;
  }

  async waitUntilBuildFinished(id: string): Promise<void> {
    return new Promise((resolve) => {
      const timer = setInterval(async () => {
        const model = await API.getModelById(id);
        this.analyticTypesToBuild.find((x) => x.selectedVersion === id).buildStatus = model.buildStatus;
        if (model.buildStatus !== ModelStatusType.Initial && model.buildStatus !== ModelStatusType.InProgress) {
          clearInterval(timer);
          resolve();
        }
      }, 2000);
    });
  }

  async handleSubmit(e: Event): Promise<void> {
    e.preventDefault();
    this.isSubmitBtnEnabled = false;

    (this.$refs.newModelForm as FormModel).validate(async (isValid) => {
      if (!isValid) {
        this.isSubmitBtnEnabled = true;
        return;
      }

      let newModel: Model = {
        analyticType: this.form.analyticType,
        trainingSetId: this.form.trainingSetId
      };

      if (this.form.isExisting) {
        newModel = {
          analyticType: this.form.analyticType,
          version: this.form.version,
          trainingStatus: TrainingStatusType.Finished,
          trainingProgress: 100
        };
      }

      try {
        await API.createModel(newModel);
        this.isModaVisible = false;
        this.resetForm();
        this.isSubmitBtnEnabled = true;
        this.$store.dispatch('model/loadData');
      } catch (err) {
        this.isSubmitBtnEnabled = true;
      }
    });
  }

  handleNotesChange(model: Model, value: string): void {
    API.patchModelById(model.id, { notes: value });
  }

  async trackBuildProgress(id: string): Promise<void> {
    const model = await API.getModelById(id);
    if (this.analyticTypesToBuild.find((x) => x.selectedVersion === id)) {
      this.analyticTypesToBuild.find((x) => x.selectedVersion === id).buildStatus = model.buildStatus;
    }
    if (model.buildStatus === ModelStatusType.Initial || model.buildStatus === ModelStatusType.InProgress) {
      // recursive run
      setTimeout(() => this.trackBuildProgress(id), 2000);
    }
    this.$store.dispatch('model/loadData');
  }

  async handleAction(id: string, e: MenuEvent): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    switch (e.key) {
      case 'retrain':
        await API.retrainModel(id);
        break;
      case 'build':
        API.buildModel(id);
        setTimeout(() => this.trackBuildProgress(id), 2000);
        break;
      default:
        break;
    }
    this.$store.dispatch('showGlobalLoader', false);
  }

  async handleEnvAction(id: string, env: string, e: MenuEvent): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    switch (e.key) {
      case 'deploy':
        await API.deployModel(id, [env as EnvType]);
        break;
      default:
        break;
    }
    this.$store.dispatch('model/loadData');
    this.$store.dispatch('showGlobalLoader', false);
  }

  handleExistingChange(): void {
    if (!this.form.isExisting) {
      this.form.version = undefined;
      return;
    }
    this.form.trainingSetId = undefined;
  }

  showModal(): void {
    this.loadTrainingSets();

    this.isModaVisible = true;
  }

  showAnalyticSelection(): void {
    const validAnalyticTypes = [
      'sat-sugar',
      'sat-harvest',
      'deforestation',
      'land-use',
      'sowing',
      'flowering',
      'weeds'
    ];
    this.$store.state.model.filteredData
      .filter((model) => validAnalyticTypes.includes(model.analyticType))
      .forEach((model) => {
        const analytic = this.analyticTypesToBuild.find((x) => x.analytic === model.analyticType);
        if (!analytic) {
          this.analyticTypesToBuild.push({
            analytic: model.analyticType,
            selected: false,
            versions: [{ id: model.id, version: model.version }],
            selectedVersion: model.id,
            buildStatus: model.buildStatus
          });
        } else {
          analytic.versions.push({ id: model.id, version: model.version });
        }
      });
    if (this.isBuildInProgress) {
      const selectedModels = this.analyticTypesToBuild
        .filter((x) => this.analyticsToBuild.includes(x.analytic) && x.buildStatus === ModelStatusType.InProgress)
        .map((x) => x.selectedVersion);
      for (const id of selectedModels) {
        this.waitUntilBuildFinished(id);
      }
    }
    this.isAnalyticSelectionVisisble = true;
  }

  handleAnalyticTypeChange(): void {
    this.form.trainingSetId = undefined;

    this.loadTrainingSets(); // load trainingSets for particular analytic
  }

  getFormatedDate(ISODate: string): string {
    return ISODate.slice(0, 19);
  }

  async loadTrainingSets(): Promise<void> {
    this.trainingSets = await API.getTrainingSets(this.form.analyticType);
  }

  async syncTrainingSets(): Promise<void> {
    try {
      this.isTrainingSetsLoading = true;
      await API.syncTrainingSets(this.form.analyticType);
      await this.loadTrainingSets();
    } finally {
      this.isTrainingSetsLoading = false;
    }
  }

  showErrorLogModal(model: Model): void {
    this.errorLog = model.buildErrorLog;
    this.isErrorLogModaVisible = true;
  }

  handleErrorLogOk(): void {
    this.errorLog = '';
    this.isErrorLogModaVisible = false;
  }
}
