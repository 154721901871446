import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import router from './router';
import store from './store';
import dateFilter from '@/filters/date';
import timeFilter from '@/filters/time';
import { jiraLink } from '@/filters/area';
import roundFilter from '@/filters/round';
import 'mapbox-gl/dist/mapbox-gl.css';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import {
  Button,
  Spin,
  Table,
  Menu,
  Icon,
  Input,
  Collapse,
  Progress,
  Select,
  Tag,
  DatePicker,
  Slider,
  Steps,
  Popover,
  InputNumber,
  Dropdown,
  Checkbox,
  Row,
  Col,
  List,
  Modal,
  FormModel,
  Tabs,
  Layout,
  Card
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false;
Vue.use(VueClipboard);

Vue.use(List);
Vue.use(Button);
Vue.use(Spin);
Vue.use(Table);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Collapse);
Vue.use(Progress);
Vue.use(Select);
Vue.use(Tag);
Vue.use(DatePicker);
Vue.use(Slider);
Vue.use(Steps);
Vue.use(Popover);
Vue.use(InputNumber);
Vue.use(Dropdown);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Modal);
Vue.use(FormModel);
Vue.use(Tabs);
Vue.use(Layout);
Vue.use(Card);

Vue.filter('formatDate', dateFilter);
Vue.filter('timeFilter', timeFilter);
Vue.filter('jiraLink', jiraLink);
Vue.filter('round', roundFilter);

const injectUserSnap = () => {
  (window as any).onUsersnapCXLoad = function (api) {
    api.init({ button: null });
    (window as any).Usersnap = api;
  };
  const script = document.createElement('script') as any;
  script.defer = 1;
  script.src = 'https://widget.usersnap.com/load/e1d754ae-d2ae-49f8-8352-a1b2b7310c96?onload=onUsersnapCXLoad';
  document.getElementsByTagName('head')[0].appendChild(script);
};
injectUserSnap();

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    release: process.env.VUE_APP_RELEASE,
    integrations: [
      new BrowserTracing({
        // TODO: we don't track backend APIs now as it's creating too many OPTIONS/Preflight request
        tracingOrigins: ['dont-track-anything']
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
